<template>
  <div id='certificate-print' class='certificate-print relative' style='height: 10.95in; width: 8.5in;'>
    <img :src='image64' class='w-full h-full z-0 top-0 p-4 absolute' id='certificate-image-bg' />
    <div
     class='flex flex-col justify-center items-center gap-y-1 w-full h-full absolute z-10'
      style='font-family: serif;'>
      <div class='relative w-full h-full'>
        <div class='absolute flex flex-row justify-center'
          style='top: 300px; right: 100px;' >
          <div class='text-gray-900 text-2xl uppercase font-semibold'>{{profile.name}}</div>
        </div>
        <div class='absolute flex flex-row justify-center'
          style='top: 330px; right: 100px;' >
          <div class='text-gray-900 text-xl uppercase font-semibold'>{{profile.hospitalName}}</div>
        </div>
        <h2 class='absolute text-3xl font-semibold mt-8 flex flex-row justify-center'
          style='top: 680px; left: 0px; right: 0px;' >
          {{ certificateDeliveryDate }}
        </h2>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'
import dayjs from 'dayjs'

export default {
  name: 'CertificateDownload',
  props: [
    'layout'
  ],
  data () {
    return {
      image64: '',
    }
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    pdfOptions () {
      return {
        margin:       0,
        filename:     `${this.certificateFilename}.pdf`,
        image:        { type: 'png', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      }
    },
    certificateImageUrl () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/2023files/kosso2023_particiapant_certificate_template2.png`
    },
    certificateFilename () {
      return 'particiapant_certificate'
    },
    certificateDeliveryDate () {
      return `${dayjs().format('YYYY년 MM월 DD일')}`
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
    pdfCreate () {
      var element = document.getElementById('certificate-print')
      html2pdf().set(this.pdfOptions).from(element).save()
    },
    imageToBase64 (url) {
      return fetch(url, {mode: 'cors', cache: 'no-cache', credentials: 'omit'}).then(resp => {
        return resp.arrayBuffer()
      }).then((imageBuffer) => {
        return Buffer.from(imageBuffer).toString('base64')
      })
    },
    base64Images () {
      const backgroundImagePromise = this.imageToBase64(this.certificateImageUrl)

      Promise.all([backgroundImagePromise]).then(values => {
        let image1 = document.getElementById('certificate-image-bg')
        let imagesLoadedCount = 0

        image1.src = `data:image/jpg;base64, ${values[0]}`
        
        image1.onload = () => {
          imagesLoadedCount++
          if (imagesLoadedCount == 1) {
            this.pdfCreate()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getProfile().then(() => {
      this.base64Images()
    })
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
  .certificate-print {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
</style>
